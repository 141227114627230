import _ from 'lodash'
import React from 'react'
import Slider from 'react-slick'
import * as styles from './testimonials.module.scss'
import { useTranslation } from 'react-i18next'

const Testimonials = ({
  data,
}) => {
    const { t } = useTranslation()
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }
  return (
    <div className={styles.testimonials__container}>
      <div className={styles.testimonials__box}>
        <div className={styles.testimonials__title}>
          <h2>{t('home.testi.title')}</h2>
        </div>
        <div>
          <Slider
            {...settings}>
            {_.map(data, (testimonial, idx) => <div className={styles.testimonials__slider} key={idx}>
              <div className={styles.testimonials__slider__box}>

                <p className={styles.testimonials__slider__description}>{testimonial.description}</p>
                <p className={styles.testimonials__slider__title}>{testimonial.name}</p>
                <span>{testimonial.attestant_position}</span>

              </div>

            </div>)}
          </Slider>
        </div>
      </div>
    </div>

  )
}

export default Testimonials
