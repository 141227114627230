import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HomeView from './home-view';
import { makeRequest } from '../../helpers';

const HomeController = () => {
  const [homeData, setHomeData] = useState(null);
  const [roomData, setRoomData] = useState([]);
  const [eventData, setEventData] = useState([]); // State for event data
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  

  const getHomeData = useCallback(async () => {
    setLoading(true);

    const headers = {
      'Content-Type': 'application/json',
    };

    makeRequest({
      headers,
      endPoint: 'pages',
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
        slug: 'home',
      } : {
        slug: 'home'
      },
    }).then((resp) => {
      setHomeData(resp[0]);
    //   setLoading(false);
    });
  }, [i18n.language]);

  const getRoomData = useCallback(async () => {
    setLoading(true);

    const headers = {
      'Content-Type': 'application/json',
    };

    // Modify this API request according to your API endpoint for roomData
    makeRequest({
      headers,
      custom: true, // Assuming this is needed for roomData
      endPoint: 'getRooms', // Modify to match your API endpoint
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
      } : {},
    }).then((resp) => {
      setRoomData(resp);
    //   setLoading(false);
    });
  }, [i18n.language]);

  // Function to fetch event data
  const getEventData = useCallback(async () => {
    setLoading(true);

    const headers = {
      'Content-Type': 'application/json',
    };

    makeRequest({
      headers,
      endPoint: 'event', // Modify to match your API endpoint for events
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
      } : {},
    }).then((resp) => {
      setEventData(resp);
    //   setLoading(false);
    });
  }, [i18n.language]);

  useEffect(() => {
    getHomeData();
    getRoomData();
    getEventData(); // Fetch event data when the language changes
  }, [i18n.language]);

  useEffect(() => {

    console.log('HOMEDATA', homeData, roomData, eventData);

    if(homeData && roomData.length > 0 && eventData.length > 0){
        setLoading(false);
    }

  }, [homeData, roomData, eventData]);

  const viewProps = {
    data: homeData,
    roomData,
    eventData, // Pass event data to the view
    loading,
    t,
    i18n,
  };

  return <HomeView {...viewProps} />;
};

export default HomeController;
