import _ from "lodash";
import React, { useRef, useEffect, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { SliderEvents, Spinner, Video, Navbar } from "../../components";
import MapLocation from "../../components/map/map";
import mobileVideo from "../../assets/videos/Kamo-Residences-Kyoto-Reflections-Group-mobile.mp4";
import desktopVideo from "../../assets/videos/Kamo-Residences-Kyoto-Reflections-Group.mp4";
import {
	SliderExperiences,
	Introduction,
	Suites,
	Testimonials,
} from "./components";
import * as styles from "./home-styles.module.scss";
import Seo from "../../components/Seo";

const HomeView = ({
	data,
	roomData,
	eventData, // Add eventData prop
	loading,
	i18n,
	t,
}) => {
	const breakpoints = useBreakpoint();
	// console.log("SCREENSIZE", breakpoints.xs);
	//   const video = breakpoints.xs ? mobileVideo : desktopVideo; // - Old static way
	const video = breakpoints.sm
		? _.get(data, "acf.video_link_mobile")
		: _.get(data, "acf.video_link");

	if (loading) {
		return (
			<>
				<Video videoInfo={{ url: "" }} />
				<Spinner loading={loading} />
			</>
		);
	}

	// console.log("YHEAD", _.get(data, "yoast_head_json"))

	return (
		<div className={styles.home__container}>
			<Seo title="Kamo - Home" yoast={_.get(data, "yoast_head_json")} />
			<Video videoInfo={{ url: video }} />
			<div className={styles.container__booking}>
				<Navbar
					title={t("menu.bookWith")}
					loading={loading}
					t={t}
					visible
				/>
			</div>
			<Introduction data={_.get(data, "acf.image_text")} />
			<Suites roomData={roomData} t={t} />
			<SliderExperiences i18n={i18n} t={t} language={i18n.language} />
			<Testimonials data={_.get(data, "acf.testimonials")} />
			<SliderEvents eventData={eventData} />{" "}
			{/* Pass eventData to SliderEvents */}
			<div style={{marginTop:'180px'}}></div>
			<MapLocation isHome showCard home={true} menu={false}/>
		</div>
	);
};

export default HomeView;
