import _ from 'lodash'
import React from 'react'
import * as styles from './introduction-style.module.scss'

const Introduction = ({
  data,
}) => (
  <div className={styles.introduction__container}>
    <div className={styles.introduction__box}>
      <div className={styles.introduction__content}>
        <h2 className={styles.introduction__content__title}>
          {_.get(data, 'text.title')}
        </h2>
        <span className={styles.introduction__content__subtitle}>
          {_.get(data, 'text.subtitle')}
        </span>
        <p className={styles.introduction__content__description}>
          {_.get(data, 'text.description')}
        </p>
      </div>
      <div className={styles.introduction__image}>
        <img alt={_.get(data, 'image.alt')} src={_.get(data, 'image.url')} />
      </div>
    </div>

  </div>
)

export default Introduction
