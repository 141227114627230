// extracted by mini-css-extract-plugin
export var active = "home-styles-module--active--aea64";
export var arrow = "home-styles-module--arrow--4efb4";
export var arrowDisabled = "home-styles-module--arrow--disabled--b5eb9";
export var arrowLeft = "home-styles-module--arrow--left--b7bcf";
export var arrowRight = "home-styles-module--arrow--right--db7b4";
export var blink = "home-styles-module--blink--5638b";
export var bookingBtn = "home-styles-module--booking-btn--23618";
export var bookingPageHeader = "home-styles-module--booking-page-header--f874b";
export var checkout = "home-styles-module--checkout--35e07";
export var commentsSlider = "home-styles-module--commentsSlider--4776e";
export var container__booking = "home-styles-module--container__booking--bb2a3";
export var dot = "home-styles-module--dot--49bdb";
export var dots = "home-styles-module--dots--b37b2";
export var equallyBound = "home-styles-module--equallyBound--09366";
export var fader = "home-styles-module--fader--65fa2";
export var fader__slide = "home-styles-module--fader__slide--3be40";
export var featuredSlider = "home-styles-module--featuredSlider--15d0a";
export var globalWrapper = "home-styles-module--global-wrapper--398c4";
export var headerIcons = "home-styles-module--header-icons--57f1a";
export var home__container = "home-styles-module--home__container--366ff";
export var homepage = "home-styles-module--homepage--48185";
export var homepageReviews = "home-styles-module--homepage-reviews--26cc5";
export var isOpenMenu = "home-styles-module--is-open-menu--e970b";
export var keenSlider = "home-styles-module--keen-slider--4a259";
export var lineMove = "home-styles-module--lineMove--b71d7";
export var logo = "home-styles-module--logo--b8bbf";
export var navigationWrapper = "home-styles-module--navigation-wrapper--6280b";
export var noScrollbar = "home-styles-module--no-scrollbar--82fe0";
export var scrollNav = "home-styles-module--scrollNav--c54e3";
export var scrollNavWrap = "home-styles-module--scrollNavWrap--2b3b2";
export var slickSlide = "home-styles-module--slick-slide--3078c";
export var startTxtArea = "home-styles-module--startTxtArea--7b7f2";