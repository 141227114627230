// extracted by mini-css-extract-plugin
export var active = "slider-experiences-module--active--a8abb";
export var arrow = "slider-experiences-module--arrow--504e2";
export var arrowDisabled = "slider-experiences-module--arrow--disabled--af228";
export var arrowLeft = "slider-experiences-module--arrow--left--a968a";
export var arrowRight = "slider-experiences-module--arrow--right--58fbd";
export var blink = "slider-experiences-module--blink--34a91";
export var bookingBtn = "slider-experiences-module--booking-btn--8f7e2";
export var bookingPageHeader = "slider-experiences-module--booking-page-header--16b2f";
export var checkout = "slider-experiences-module--checkout--16c29";
export var commentsSlider = "slider-experiences-module--commentsSlider--2a820";
export var dot = "slider-experiences-module--dot--c3ce8";
export var dots = "slider-experiences-module--dots--22efa";
export var equallyBound = "slider-experiences-module--equallyBound--2e1c8";
export var fader = "slider-experiences-module--fader--309cb";
export var fader__slide = "slider-experiences-module--fader__slide--fae2b";
export var featuredSlider = "slider-experiences-module--featuredSlider--cffae";
export var globalWrapper = "slider-experiences-module--global-wrapper--98796";
export var headerIcons = "slider-experiences-module--header-icons--d433f";
export var homepage = "slider-experiences-module--homepage--affd8";
export var homepageReviews = "slider-experiences-module--homepage-reviews--80493";
export var isOpenMenu = "slider-experiences-module--is-open-menu--78599";
export var keenSlider = "slider-experiences-module--keen-slider--70b3f";
export var lineMove = "slider-experiences-module--lineMove--05f0f";
export var logo = "slider-experiences-module--logo--8c049";
export var navigationWrapper = "slider-experiences-module--navigation-wrapper--e6dbb";
export var notran = "slider-experiences-module--notran--5961a";
export var scrollNav = "slider-experiences-module--scrollNav--36dcf";
export var scrollNavWrap = "slider-experiences-module--scrollNavWrap--c23c5";
export var slickSlide = "slider-experiences-module--slick-slide--a6299";
export var slickSlider = "slider-experiences-module--slick-slider--12e32";
export var sliderExperiences__container = "slider-experiences-module--sliderExperiences__container--1375d";
export var sliderExperiences__content = "slider-experiences-module--sliderExperiences__content--f777e";
export var sliderExperiences__line__box = "slider-experiences-module--sliderExperiences__line__box--5fb3f";
export var sliderExperiences__line__line = "slider-experiences-module--sliderExperiences__line__line--559d1";
export var sliderExperiences__line__loaderLine = "slider-experiences-module--sliderExperiences__line__loaderLine--5e527";
export var sliderExperiences__links = "slider-experiences-module--sliderExperiences__links--f1343";
export var sliderExperiences__mouse = "slider-experiences-module--sliderExperiences__mouse--0bce5";
export var sliderExperiences__slider = "slider-experiences-module--sliderExperiences__slider--be270";
export var sliderExperiences__slider__box = "slider-experiences-module--sliderExperiences__slider__box--27d0e";
export var sliderExperiences__slider__container = "slider-experiences-module--sliderExperiences__slider__container--9791d";
export var sliderExperiences__slider__content = "slider-experiences-module--sliderExperiences__slider__content--2683a";
export var sliderExperiences__slider__explore = "slider-experiences-module--sliderExperiences__slider__explore--13a79";
export var sliderExperiences__slider__image = "slider-experiences-module--sliderExperiences__slider__image--dcfe8";
export var sliderExperiences__slider__slider = "slider-experiences-module--sliderExperiences__slider__slider--25804";
export var startTxtArea = "slider-experiences-module--startTxtArea--70c2c";