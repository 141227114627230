import { Link } from 'gatsby';
import React from 'react';
import * as styles from './card-suites-style.module.scss';
import { getRoute } from '../../../../helpers';
import { useTranslation } from 'react-i18next';

const CardSuites = ({ image, price, info, title, slug }) => {
  const { t, i18n } = useTranslation();

  const getLangLink = () => {
    const activeLanguage = i18n?.language.toLowerCase();
    let link = '';
    if (activeLanguage) {
      switch (activeLanguage) {
        case 'ja':
          link = '/ja/';
          break;
        case 'zh-hant':
          link = '/zh-hant/';
          break;
        default:
          link = '/';
      }
    }
    return link;
  };

  return (
    <div className={styles.cardSuites__container}>
      <Link
        to={`${getLangLink()}suite/${slug}`}
        className={styles.cardSuites__box}
      >
        <img src={image} alt={title} />
        <div className={styles.cardSuites__content}>
          <h2>{title}</h2>
          <span>{info}</span>
          <p>
            {t('suites.from')} {price}
          </p>
          <div className={styles.cardSuites__content__link}>
            <p>{t('home.suites.explore')}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CardSuites;
