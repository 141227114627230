// extracted by mini-css-extract-plugin
export var active = "testimonials-module--active--01a4e";
export var arrow = "testimonials-module--arrow--0d224";
export var arrowDisabled = "testimonials-module--arrow--disabled--ec740";
export var arrowLeft = "testimonials-module--arrow--left--0aa0d";
export var arrowRight = "testimonials-module--arrow--right--234eb";
export var blink = "testimonials-module--blink--aac24";
export var bookingBtn = "testimonials-module--booking-btn--ca631";
export var bookingPageHeader = "testimonials-module--booking-page-header--41cd5";
export var checkout = "testimonials-module--checkout--df432";
export var commentsSlider = "testimonials-module--commentsSlider--8d901";
export var dot = "testimonials-module--dot--5fe69";
export var dots = "testimonials-module--dots--5eecf";
export var equallyBound = "testimonials-module--equallyBound--3ec25";
export var fader = "testimonials-module--fader--7dd40";
export var fader__slide = "testimonials-module--fader__slide--2665e";
export var featuredSlider = "testimonials-module--featuredSlider--4ab1a";
export var globalWrapper = "testimonials-module--global-wrapper--0a189";
export var headerIcons = "testimonials-module--header-icons--0f393";
export var homepage = "testimonials-module--homepage--81625";
export var homepageReviews = "testimonials-module--homepage-reviews--89e55";
export var isOpenMenu = "testimonials-module--is-open-menu--4016e";
export var keenSlider = "testimonials-module--keen-slider--367c4";
export var lineMove = "testimonials-module--lineMove--4f0a9";
export var logo = "testimonials-module--logo--bd6c0";
export var navigationWrapper = "testimonials-module--navigation-wrapper--f7c7c";
export var scrollNav = "testimonials-module--scrollNav--d9ff7";
export var scrollNavWrap = "testimonials-module--scrollNavWrap--52307";
export var slickSlide = "testimonials-module--slick-slide--eecff";
export var startTxtArea = "testimonials-module--startTxtArea--7f55b";
export var testimonials__box = "testimonials-module--testimonials__box--91242";
export var testimonials__container = "testimonials-module--testimonials__container--4f835";
export var testimonials__slider = "testimonials-module--testimonials__slider--beca3";
export var testimonials__slider__box = "testimonials-module--testimonials__slider__box--064c0";
export var testimonials__slider__description = "testimonials-module--testimonials__slider__description--bd558";
export var testimonials__slider__title = "testimonials-module--testimonials__slider__title--322ad";
export var testimonials__title = "testimonials-module--testimonials__title--2a230";