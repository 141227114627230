/* eslint-disable max-len */
import _ from "lodash";
import Slider from "react-slick";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import MouseTooltip from "react-sticky-mouse-tooltip";
import { getRoute, slugify } from "../../../../helpers";
import { useExperiences } from "../../../../hooks/use-experiences";
import * as styles from "./slider-experiences.module.scss";

const SliderExperiences = ({ i18n, t }) => {
	const sliderRef = useRef(null);
	const { experiences } = useExperiences();
	const [showMouse, setShowMouse] = useState(false);
	const [width, setWith] = useState(false);
	const [index, setIndex] = useState(0);

	// const getWidth = (items, idx) => (idx * 100) / items.length
	const settings = {
		dots: false,
		infinite: true,
		speed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: false,
		autoplaySpeed: 6000,
	};

	useEffect(() => {
		const timer1 = setTimeout(() => setWith(true), 300);
		return () => {
			clearTimeout(timer1);
		};
	}, [index]);

	const getIndex = (e) => {
		setIndex(e);
	};

	const takeClass = () => {
		setWith(false);
	};

	const handleBoxToggle = (e) => {
		if (e.type === "mouseover") {
			setShowMouse(false);
		} else {
			setShowMouse(false);
		}
	};

	return (
		<div className={styles.sliderExperiences__container}>
			<div className={styles.sliderExperiences__content}>
				<h2>{t("home.experiences.title")}</h2>
				<p>{t("home.experiences.description")}</p>
			</div>
			<div
				style={{
					position: "relative",
				}}
			>
				<div className={styles.sliderExperiences__line__box}>
					<div className={styles.sliderExperiences__line__line}>
						<span
							style={{
								width: width ? "100%" : "0%",
							}}
							className={
								width
									? styles.sliderExperiences__line__loaderLine
									: null
							}
						></span>
					</div>
				</div>
				<div
					onMouseOver={handleBoxToggle}
					onMouseLeave={handleBoxToggle}
				>
					<Slider
						ref={sliderRef}
						className={styles.sliderExperiences__slider__container}
						beforeChange={takeClass}
						afterChange={(e) => getIndex(e)}
						{...settings}
					>
						{_.map(experiences, (item, idx) => (
							<Link
								key={item.title + idx}
								title={slugify(item.title, "-")}
								to={getRoute(
									`/experiences/#${slugify(item.title, "-")}`,
									i18n.language
								)}
							>
								<div
									className={
										styles.sliderExperiences__slider__slider
									}
								>
									<div
										className={
											styles.sliderExperiences__slider__box
										}
									>
										<div
											className={
												styles.sliderExperiences__slider__content
											}
										>
											<h2
												style={
													idx === 0
														? { color: "#fff" }
														: {}
												}
											>
												{item.title}
											</h2>
											<p
												style={
													idx === 0
														? { color: "#fff" }
														: {}
												}
											>
												{item.excerpt}
											</p>
										</div>
									</div>
									<div
										className={
											styles.sliderExperiences__slider__explore
										}
									>
										<p>{t("home.experiences.button")}</p>
									</div>
									<div
										className={
											styles.sliderExperiences__slider__image
										}
									>
										<img
                                            alt={item.title}
											width="100%"
											src={item.images.home_image.url}
										/>
									</div>
								</div>
							</Link>
						))}
					</Slider>
				</div>
				{showMouse && (
					<MouseTooltip
						visible={true}
						offsetX={5}
						offsetY={5}
						style={{
							zIndex: 100000,
						}}
					>
						<div className={styles.sliderExperiences__mouse}>
							<p>{t("home.experiences.button")}</p>
						</div>
					</MouseTooltip>
				)}
				<div className={styles.sliderExperiences__links}>
					{_.map(experiences, (ex, idx) => (
						<p
							onClick={() => sliderRef.current.slickGoTo(idx)}
							key={idx}
							className={index === idx ? "active" : ""}
						>
							{ex.title}
						</p>
					))}
				</div>
			</div>
		</div>
	);
};

export default SliderExperiences;
