// extracted by mini-css-extract-plugin
export var active = "suites-styles-module--active--43f11";
export var arrow = "suites-styles-module--arrow--d36dd";
export var arrowDisabled = "suites-styles-module--arrow--disabled--ccda9";
export var arrowLeft = "suites-styles-module--arrow--left--45bb4";
export var arrowRight = "suites-styles-module--arrow--right--cb881";
export var blink = "suites-styles-module--blink--7bf88";
export var bookingBtn = "suites-styles-module--booking-btn--8c0ec";
export var bookingPageHeader = "suites-styles-module--booking-page-header--60332";
export var checkout = "suites-styles-module--checkout--449c1";
export var commentsSlider = "suites-styles-module--commentsSlider--eaf7a";
export var dot = "suites-styles-module--dot--a8922";
export var dots = "suites-styles-module--dots--97bfa";
export var equallyBound = "suites-styles-module--equallyBound--e3251";
export var fader = "suites-styles-module--fader--832a9";
export var fader__slide = "suites-styles-module--fader__slide--a6fa5";
export var featuredSlider = "suites-styles-module--featuredSlider--75592";
export var globalWrapper = "suites-styles-module--global-wrapper--4935f";
export var headerIcons = "suites-styles-module--header-icons--cc9e3";
export var homepage = "suites-styles-module--homepage--69916";
export var homepageReviews = "suites-styles-module--homepage-reviews--52cc8";
export var isOpenMenu = "suites-styles-module--is-open-menu--4a1f4";
export var keenSlider = "suites-styles-module--keen-slider--7ced3";
export var lineMove = "suites-styles-module--lineMove--2740f";
export var logo = "suites-styles-module--logo--642dd";
export var navigationWrapper = "suites-styles-module--navigation-wrapper--755ad";
export var scrollNav = "suites-styles-module--scrollNav--629d3";
export var scrollNavWrap = "suites-styles-module--scrollNavWrap--97f34";
export var slickSlide = "suites-styles-module--slick-slide--e7025";
export var startTxtArea = "suites-styles-module--startTxtArea--b3f42";
export var suites__box = "suites-styles-module--suites__box--9e7ca";
export var suites__card__container = "suites-styles-module--suites__card__container--86efa";
export var suites__container = "suites-styles-module--suites__container--f4d30";
export var suites__content = "suites-styles-module--suites__content--fe597";
export var suites__content__description = "suites-styles-module--suites__content__description--541ac";
export var suites__content__title = "suites-styles-module--suites__content__title--8ba54";