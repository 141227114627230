import React from 'react';
import _ from 'lodash';
import * as styles from './suites-styles.module.scss';
import CardSuites from '../card-suites';

const Suites = ({ t, roomData }) => {

  return (
    <div className={styles.suites__container}>
      <div className={styles.suites__box}>
        <div className={styles.suites__content}>
          {/* I ask to ram to add in the backend 09/02/22 */}
          <h2 className={styles.suites__content__title}>{t('home.suites.title')}</h2>
          <p className={styles.suites__content__description}>{t('home.suites.description')}</p>
        </div>

        <div className={styles.suites__card__container}>
          {roomData.map((suite) => (
            <CardSuites
              key={suite.roomTypeID}
              image={suite.roomTypePhotos && suite.roomTypePhotos.length > 0 ? suite.roomTypePhotos[0] : null}
              title={(suite.title ? `${suite.title} ${t('suite.subtitle')}` : '') || suite.roomTypeName}
              info={`${t('suites.max', { person: suite.maxGuests })}, ${suite.measurement} sqm`}
              price={`¥ ${Number(suite.starting_price).toLocaleString()}`}
              slug={suite.slug}
              t={t}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Suites;
