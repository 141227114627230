// extracted by mini-css-extract-plugin
export var active = "card-suites-style-module--active--40c13";
export var arrow = "card-suites-style-module--arrow--c84c9";
export var arrowDisabled = "card-suites-style-module--arrow--disabled--6f17d";
export var arrowLeft = "card-suites-style-module--arrow--left--3a43e";
export var arrowRight = "card-suites-style-module--arrow--right--c7dbe";
export var blink = "card-suites-style-module--blink--f8d98";
export var bookingBtn = "card-suites-style-module--booking-btn--7b40b";
export var bookingPageHeader = "card-suites-style-module--booking-page-header--9cd4e";
export var cardSuites__box = "card-suites-style-module--cardSuites__box--5b9c4";
export var cardSuites__container = "card-suites-style-module--cardSuites__container--037fe";
export var cardSuites__content = "card-suites-style-module--cardSuites__content--77aa3";
export var cardSuites__content__link = "card-suites-style-module--cardSuites__content__link--c13ff";
export var checkout = "card-suites-style-module--checkout--710a4";
export var commentsSlider = "card-suites-style-module--commentsSlider--84319";
export var dot = "card-suites-style-module--dot--c64e6";
export var dots = "card-suites-style-module--dots--75376";
export var equallyBound = "card-suites-style-module--equallyBound--31c15";
export var fader = "card-suites-style-module--fader--f5820";
export var fader__slide = "card-suites-style-module--fader__slide--5265c";
export var featuredSlider = "card-suites-style-module--featuredSlider--edf20";
export var globalWrapper = "card-suites-style-module--global-wrapper--0fe02";
export var headerIcons = "card-suites-style-module--header-icons--242ae";
export var homepage = "card-suites-style-module--homepage--b9f79";
export var homepageReviews = "card-suites-style-module--homepage-reviews--f787c";
export var isOpenMenu = "card-suites-style-module--is-open-menu--99482";
export var keenSlider = "card-suites-style-module--keen-slider--e6916";
export var lineMove = "card-suites-style-module--lineMove--6d965";
export var logo = "card-suites-style-module--logo--c6855";
export var navigationWrapper = "card-suites-style-module--navigation-wrapper--58c81";
export var scrollNav = "card-suites-style-module--scrollNav--2a481";
export var scrollNavWrap = "card-suites-style-module--scrollNavWrap--1dea3";
export var slickSlide = "card-suites-style-module--slick-slide--c7bc0";
export var startTxtArea = "card-suites-style-module--startTxtArea--afb10";